<template>
  <Layout v-load="loading">
    <h2 class="my-3">Feed</h2>
    <div class="events">
      <div
        v-for="(event, key) in events"
        :key="key"
        class="events__group"
      >
        <div
          class="d-inline-block rounded p-2 bg-success text-light font-weight-bold mb-3"
        >
          {{key}}
        </div>
        <div>
          <b-card
            v-for="item in event"
            :key="item.id"
            :class="[{'with-link': getLink(item) }, 'events__item mb-3']"
            @click="handleEventClick(item)"
          >
            <div :class="['events__item__bell', getBellBg(item)]">
              <i class="bx bxs-bell font-size-18" />
            </div>
            <div
              class="d-flex align-items-center justify-content-between"
            >
              <span style="flex: 1;">{{ item.title }}</span>
              <div class="d-flex align-items-center">
                <i class="bx bx-time-five font-size-16 mr-1" />
                <span>{{ timeAgo(item.created_at) }}</span>
              </div>
            </div>
            <div
              v-if="hasBody(item)"
              class="border-top mt-3 pt-3 text-secondary"
            >
              {{ getBody(item) }}
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import moment from 'moment';
import Layout from '@/router/layouts/main.vue';

export default {
  name: 'FeedPage',
  components: { Layout },
  data() {
    return {
      currentPage: 1,
    }
  },
  async mounted() {
    await this.$store.dispatch('events/getEvents', this.queryParams);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    this.$store.dispatch('events/deleteEvents');
  },
  methods: {
    async handleScroll() {
      const height = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );
      if (
          window.pageYOffset + 200 >= height - document.documentElement.clientHeight
          && this.currentPage < this.pageCount
          && !this.disableLoad
          && !this.loading
      ) {
        this.currentPage += 1;
        await this.$store.dispatch('events/getEvents', this.queryParams);
      }
    },
    handleEventClick(item) {
      const link = this.getLink(item);
      if (link) {
        this.$router.push(link);
      }
    },
    timeAgo(date) {
      return moment(date).fromNow();
    },
    hasBody(event) {
      if (event.type === 'invite_sent') { return false; }
      if (event.type === 'profile_filled') { return false; }
      if (event.type === 'note_created') { return true; }
      if (event.type === 'employee_dismissed') { return true; }
      if (event.type === 'interview_created') { return true; }

      return false;
    },
    getBody(event) {
      if (event.type === 'invite_sent') { return ''; }
      if (event.type === 'profile_filled') { return ''; }
      if (event.type === 'note_created') { return event.eventable ? event.eventable.text : ''; }
      if (event.type === 'employee_dismissed') { return event.eventable.dismiss_note; }
      if (event.type === 'interview_created') { return event.eventable ? event.eventable.note : ''; }
    },
    getBellBg(event) {
      if (event.type === 'invite_sent') { return 'bg-warning'; }
      if (event.type === 'created') { return 'bg-success'; }
      if (event.type === 'updated') { return 'bg-success'; }
      if (event.type === 'assessment_record_added') { return 'bg-warning'; }
      if (event.type === 'profile_filled') { return 'bg-primary'; }
      if (event.type === 'note_created') { return 'bg-warning'; }
      if (event.type === 'employee_dismissed') { return 'bg-danger'; }
      if (event.type === 'interview_created') { return 'bg-info'; }
      if (event.type === 'employee_added') { return 'bg-success'}

      return 'bg-success';
    },
    getLink(event) {
      if (event.type === 'assessment_record_method_added') { return '/settings'; }
      if (event.type === 'assessment_record_added') { return '/assessment-records'; }
      if (event.type === 'profile_filled') {
        const { eventable } = event;
        return eventable ? `/employees/${eventable.employee_uid}` : '';
      }
      if (event.type === 'note_created') {
        const { eventable } = event;
        return eventable ? `/employees/${eventable.employee_uid}` : '';
      }
      if (event.type === 'employee_dismissed') { return '/employees-archived'; }
      if (event.type === 'interview_created') { return '/interviews'; }
      if (event.type === 'employee_added') {
        const { eventable } = event;
        return eventable ? `/employees/${eventable.uid}` : '/employees';
      }
      if (event.type === 'created' && event.eventable_type === "App\\Contract") {
        const { eventable } = event;
        return eventable ? `/employees/${eventable.employee_uid}` : '/employees';
      }

      return null;
    }
  },
  computed: {
    events() {
      return this.$store.getters['events/gruppedEventsByDate'];
    },
    queryParams() {
      return {
        page: this.currentPage
      }
    },
    pageCount() {
      return this.$store.state.events.pageCount;
    },
    loading() {
      return this.$store.state.events.loading;
    }
  }
}
</script>

<style lang="scss" scoped>
  .events {
    position: relative;

    .with-link {
      cursor: pointer;
    }

    &::before {
      content: '';
      top: 0px;
      left: 30px;
      width: 4px;
      height: 100%;
      z-index: 1;
      background: #ddd;
      position: absolute;
    }
    &__group {
      z-index: 2;
      position: relative;
    }
    &__item {
      margin-left: 60px;
      position: relative;

      &__bell {
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        left: -43px;
      }
    }
  }
</style>
